import React, { useState, useRef, useEffect } from "react";
import { styles } from "./SelectInput.style";

interface SelectProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label?: string;
  message?: string;
  hasError?: boolean;
  customFrontIcon?: React.ReactNode;
  isShort?: boolean;
  placeholder?: string;
  testId?: string;
  hideAfterSelect?: boolean;
  withInputField?: boolean;
  inputValue?: string;
  onChangeInput?: (input: string) => void;
  onBlurInput?: () => void;
  onChildKeyDown?: () => void;
}

export const SelectInput: React.FC<React.PropsWithChildren<SelectProps>> = (props) => {
  const {
    label,
    children,
    message,
    id,
    disabled,
    hasError,
    customFrontIcon,
    placeholder,
    isShort,
    testId,
    hideAfterSelect,
    withInputField,
    inputValue = "",
    onChangeInput,
    onBlurInput,
    onChildKeyDown,
  } = props;
  const [showSelectSpace, setShowSelectSpace] = useState(false);
  const selectInputRef = useRef(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleGlobalClick = (event: MouseEvent) => {
    if (selectInputRef.current && !selectInputRef.current.contains(event.target as Node)) {
      setShowSelectSpace(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleGlobalClick);
    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  const handleKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (!disabled) {
        setShowSelectSpace(!showSelectSpace);
      }
    }
  };

  const handleKeyDownList = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (!menuRef.current) return;

    const items = Array.from(menuRef.current.querySelectorAll("li"));
    const currentIndex = items.findIndex((item) => document.activeElement === item);

    if (event.key === "ArrowDown") {
      if (currentIndex === -1) {
        event.preventDefault();
        items[1]?.focus();
      } else {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % items.length;
        (items[nextIndex] as HTMLElement)?.focus();
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      const prevIndex = (currentIndex - 1 + items.length) % items.length;
      (items[prevIndex] as HTMLElement)?.focus();
    } else if (event.key === "Tab") {
      setShowSelectSpace(false);
    } else if (event.key === "Enter" && onChildKeyDown) {
      event.preventDefault();
      onChildKeyDown();
      setShowSelectSpace(false);
    }
  };

  return (
    <div ref={selectInputRef} css={styles.root(isShort)} data-testid={testId}>
      <div
        css={[styles.form(), hasError ? styles.withError : "", disabled ? styles.disabled : ""]}
        onKeyDown={handleKey}
        role={"button"}
        onFocus={() => !disabled && setShowSelectSpace(!showSelectSpace)}
      >
        <fieldset css={styles.content(isShort, showSelectSpace)}>
          {customFrontIcon && <div css={styles.frontIcon}>{customFrontIcon}</div>}
          <div css={styles.inputContainer}>
            <label css={styles.labelText(!!customFrontIcon)} id={id}>
              {label}
            </label>
            {withInputField ? (
              <input
                type="text"
                value={inputValue}
                onChange={(e) => {
                  onChangeInput?.(e.target.value);
                  setShowSelectSpace(true);
                }}
                onBlur={() => onBlurInput?.()}
                placeholder={placeholder}
                css={styles.input}
              />
            ) : (
              <div tabIndex={0} css={styles.placeholderText(isShort)}>
                {placeholder}
              </div>
            )}
            <div css={styles.selectArrow(isShort, showSelectSpace)} />
          </div>
        </fieldset>
        {!isShort && <div css={styles.info(!!message)}>{message}</div>}
      </div>

      <div
        css={styles.selectSpace(isShort, showSelectSpace)}
        onClick={() => hideAfterSelect && setShowSelectSpace(false)}
      >
        <ul onKeyDown={handleKeyDownList} ref={menuRef} tabIndex={0}>
          {children}
        </ul>
      </div>
    </div>
  );
};
